/*
 * 업무구분: 계약관리
 * 화 면 명: MSPPM875P
 * 화면설명: 유지체크명세상세
 * 접근권한: 
 * 작 성 일: 2022.12.22
 * 작 성 자: 김연숙
 */
<template>
<!-- type="popup" -->
 <ur-page-container class="msp" :show-title="true" title="유지체크명세 상세" type="subpage" @on-header-left-click="fn_Back">
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area pt15">
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list">
         <div class="list-item w100 pb0">
          <div class="list-item__contents">
            <div class="list-item__contents__title fexJsCtTy1"> 
              <span class="name txtSkip fexInt maxW250">{{ lv_pMUkepChkSpcf01VO.custNm }}</span>
              <!-- 상태: 배지 형태로 변경 -->
              <div class="bage-area">                                                
                <mo-badge class="badge-sample-badge lightred sm" :text="lv_pMUkepChkSpcf01VO.condition" shape="status">{{ lv_pMUkepChkSpcf01VO.condition }}</mo-badge> 
              </div>
            </div>
            <!-- 상품명 -->
            <div class="list-item__contents__info">
              <span class="txtSkip maxW250">{{lv_pMUkepChkSpcf01VO.prdtNm}}</span>
            </div>
            <!-- 보험료(모집고월초P) -->
            <div class="list-item__contents__info">
              <span class="fs14rem">보험료</span><em>|</em><span class="crTy-blue3"><b>{{lv_pMUkepChkSpcf01VO.clctBomthAmtCommas}}원</b></span>
            </div>
            <!-- 종납년월/횟수, 이체의뢰건수/미이체의뢰건수 -->
            <div class="list-item__contents__info">
              <div class="info_box">
                <span class="fs14rem">종납월 <b class="ml10">{{lv_pMUkepChkSpcf01VO.ltpadYm}}</b></span><em>|</em>
                <span class="fs14rem">납입회차 <b class="ml10">{{lv_pMUkepChkSpcf01VO.rlpadTms}}회</b></span>
                <span class="fs14rem">이체/미이체<b class="ml10">{{lv_pMUkepChkSpcf01VO.atrAskCnt}}/{{lv_pMUkepChkSpcf01VO.trsfImpsCnt}}회분</b></span>
              </div>
            </div>
          </div>
         </div> 
        </ur-box-container>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="msp-detail-lst">  
        <ul class="lst">
          <li>
            <span class="tit">계약번호(상태)</span>
            <span class="txt">{{lv_pMUkepChkSpcf01VO.contNo1}}({{lv_pMUkepChkSpcf01VO.condition}})</span>
          </li>
           <li>
            <span class="tit">단체계약번호</span>
            <span class="txt">{{lv_pMUkepChkSpcf01VO.grno}}</span>
          </li>
           <li>
            <span class="tit">모집고월초P</span>
            <span class="txt">{{lv_pMUkepChkSpcf01VO.clctBomthAmtCommas}}</span>
          </li>
           <li>
            <span class="tit">환산성적</span>
            <span class="txt">{{lv_pMUkepChkSpcf01VO.ukepMpadPrmCommas}}</span>
          </li>
          <li>
            <span class="tit">입금일</span>
            <span class="txt">{{$commonUtil.dateDashFormat(lv_pMUkepChkSpcf01VO.ukepYmd)}}</span>
          </li>
          <li>
            <span class="tit">납입방법</span>
            <span class="txt">{{lv_pMUkepChkSpcf01VO.padMthCdNm + '(' + lv_pMUkepChkSpcf01VO.trsfBnkCdNm + ', ' + lv_pMUkepChkSpcf01VO.trsfHopDdCd + '일)'}}</span>
          </li>
          <li>
            <span class="tit">모집년월</span>
            <span class="txt">{{lv_pMUkepChkSpcf01VO.contYm}}</span>
          </li>
          <li>
            <span class="tit">최초모집FC명</span>
            <span class="txt">{{lv_pMUkepChkSpcf01VO.clctCnsltNm01}}</span>
          </li>
        </ul>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPPM875P",
  screenId: "MSPPM875P",
  props: {
    pPMUkepChkSpcf01VO: Object, // 선택한 유지체크명세 항목
    pChnlCustId: String,        // 고객 채널ID
  },

  components: {
  },

  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 선택한 고객 데이터
      lv_pMUkepChkSpcf01VO: {},
      lv_chnlCustId: '',
    };
  },

  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created () {
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    let lv_Vm = this
    lv_Vm.lv_pMUkepChkSpcf01VO = lv_Vm.$props.pPMUkepChkSpcf01VO
    lv_Vm.lv_chnlCustId = lv_Vm.$props.pChnlCustId

    lv_Vm.userInfo = lv_Vm.getStore('userInfo').getters.getUserInfo
    lv_Vm.lv_userNm = lv_Vm.userInfo.userNm

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },

  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /*****************************************
     * Function명  : fn_Back
     * 설명        : 뒤로가기 (history back)
     *****************************************/
    fn_Back () {
      this.$emit('onPopupClose');
    },
  }
};
</script>
<style scoped>
</style>